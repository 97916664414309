import {reactive} from "vue-demi";
import {appinfo} from "@/isr-framework/apis/project-config";

const useSimulateState = reactive({
    user: {
        userid: "",
        wx_logincode: "",
        userflag: false,
        sellerid: "",
        info: ""
    },
    mainView: {
        path: "",
        level: 0,
        query: {}
    },
    currentView: {
        path: "",
        level: 1,
        query: {}
    },
    historyView: []
})

/**
 * 路由初始化 获取路由状态
 */
export const useSimulateRoute = () => {
    function setSimulateRouter() {
        sessionStorage.setItem('simulateWscRoute', JSON.stringify(useSimulateState))
    }

    function initUseSimulateRouter(mainView) {
        let simulateWacData = JSON.parse(sessionStorage.getItem('simulateWscRoute'))
        useSimulateState.mainView = {...mainView}
        useSimulateState.currentView = {...mainView}
        if (simulateWacData) {
            useSimulateState.user.userid = simulateWacData.user.userid
        }
        useSimulateState.user.wx_logincode = appinfo().projectConfig.wx_logincode
        useSimulateState.user.sellerid = ""
    }

    function getUserid() {
        return useSimulateState.user.userid
    }

    function getQuery() {
        return useSimulateState.currentView.query
    }


    return {
        useSimulateState,
        initUseSimulateRouter,
        setSimulateRouter,
        getUserid,
        getQuery
    }
}

/**
 * 跳转页面的路由，方法，主页的level为0，一级页面的level为1
 */
export const simulateRouter = (view, query, level) => {
    useSimulateState.historyView.push({
        path: useSimulateState.currentView.path,
        level: useSimulateState.currentView.level,
        query: useSimulateState.currentView.query
    })

    useSimulateState.currentView.path = view;
    useSimulateState.currentView.query = query;
    useSimulateState.currentView.level = level;
    useSimulateRoute().setSimulateRouter()
}

//返回历史上级界面
export const simulateRouteReturn = () => {
    let history = [...useSimulateState.historyView].reverse().find(item => item.level === (useSimulateState.currentView.level - 1))
    if (!history) {
        simulateRouter(useSimulateState.mainView.path, useSimulateState.mainView.query, useSimulateState.mainView.level)
    } else {
        simulateRouter(history.path, history.query, history.level)
    }
}

