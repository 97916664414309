/** **   request.js   ****/
// 导入axios
import axios from 'axios'
// 使用element-ui Message做消息提醒
import {ElMessage} from 'element-plus'
// import router from "@/router/index-framework";
// import paradata from "bootstrap/js/src/dom/paradata";
// 1. 创建新的axios实例，
axios.defaults.withCredentials = true;
import {AES, MD5} from './crypto-encoder';
import {useSimulateRoute} from "@/simulate/js/controller";

const secret = 'secret'/**projectConfig.secret**/;
const http_encrypt = false;
export const projectResponseInterceptors = []

const service = axios.create({
    // 公共接口--这里注意后面会讲
    baseURL: "/api",
    // 超时时间 单位是ms，这里设置了3s的超时时间
    timeout: 20 * 1000,
    headers: {
        'Content-type': 'application/json;charset=utf8'
    }
})
// 2.请求拦截器
service.interceptors.request.use(config => {
    const isrtoken = sessionStorage.getItem('isrtoken')
    if (isrtoken) {
        config.headers['isrtoken'] = isrtoken
    }

    console.log("request.js->req==", config)
    // 发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    const {paradata} = config
    const cryptoEncoder = new AES(secret);
    const paramsStringify = JSON.stringify(paradata);
    let encrypt = cryptoEncoder.aesEncrypt(paramsStringify);
    let sign = MD5(`params=${encrypt}&key=${secret}`).toUpperCase();
    if (http_encrypt) {
        config.paradata = {
            params: encrypt,
            sign
        }
        config.headers = {
            ...config.headers,
            sign
        }
    }
    /* config.paradata = JSON.stringify(config.paradata); //数据转化,也可以使用qs转换 */
    /* config.headers = {
          'Content-Type':'application/json' //配置请求头
      } */
    // 如有需要：注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
    //  const token = getCookie('名称');//这里取token之前，你肯定需要先拿到token,存一下
    //  if(token){
    //  config.params = {'token':token} //如果要求携带在参数中
    // config.headers.token= token; //如果要求携带在请求头中
    //  }
    return config
}, error => {
    Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
    if (response.headers.isrtoken) {
        sessionStorage.setItem('isrtoken', response.headers.isrtoken)
    }
    // 接收到响应数据并成功后的一些共有的处理，关闭loading等
    console.log('返回的数据信息------>', response)
    // if(response.data.retcode === 9990 || response.data.retcode === 9991) {
    //     router.push({ name: 'accsess-denied', state: {code: response.data.retcode, ip: response.data.retdata}})
    // }
    if (response.data.retcode === 500) {
        ElMessage.error('访问发生异常，请稍后再试！')
    }
    //项目响应拦截事件处理
    projectResponseInterceptors.forEach(item => {
        item(response)
    })
    if (response.data.retcode === 9999) {
        return response.data;
    }
    return response.data
}, error => {
    console.log(error)
    /** *** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
        // 1.公共错误处理
        // 2.根据响应码具体处理
        switch (error.response.status) {
            case 400:
                error.message = '错误请求'
                break
            case 401:
                error.message = '未授权，请重新登录'
                break
            case 403:
                error.message = '拒绝访问'
                break
            case 404:
                error.message = '请求错误,未找到该资源'
                break
            case 405:
                error.message = '请求方法未允许'
                break
            case 408:
                error.message = '请求超时'
                break
            case 500:
                error.message = '服务器响应超时，请稍后再试'
                break
            case 501:
                error.message = '网络未实现'
                break
            case 502:
                error.message = '网络异常，请稍后再试！'
                break
            case 503:
                error.message = '服务不可用'
                break
            case 504:
                error.message = '网络超时'
                break
            case 505:
                error.message = 'http版本不支持该请求'
                break
            default:
                error.message = `连接错误${error.response.status}`
        }
    } else {
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
            error.message = '连接服务器超时'
        } else {
            error.message = '连接服务器失败'
        }
    }
    ElMessage.error(error.message)
    /** *** 处理结束 *****/
    // 如果不需要错误处理，以上的处理过程都可省略
    return Promise.reject(error)
})


// 4.导入文件
export default service