import {ref} from "vue";

export class AppInfo {
    constructor(appid, appname_isr_core, appname_web, secretKey, projectConfig) {
        this.appid = appid;
        this.appname_isr_core = appname_isr_core;
        this.appname_web = appname_web;
        this.secretKey = secretKey;
        this.projectConfig = projectConfig;
    }
}

export const appinfoItem = ref({})
export const appinfo = () => {
    return appinfoItem.value
}
export const setAppinfoItem = (appid, appname_isr_core, appname_web, secretKey, projectConfig) => {
    appinfoItem.value = new AppInfo(appid, appname_isr_core, appname_web, secretKey, projectConfig)
}
